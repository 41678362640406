// Frame

.frame {
    position: relative;

    > *:last-child {
        margin-bottom: 0;
    }

    &-header {
        > *:last-child {
            margin-bottom: 1.5rem;
        }
        
        @include media-breakpoint-up(xl) {
            > *:last-child {
                margin-bottom: 3.125rem;
            }
        }

        @include media-breakpoint-down(xs) {
            word-break: break-word;
        }
    }
}

.frame-default {
    margin-bottom: 4.5rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 8.5rem;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 12.5rem;
    }
}

.frame-type-gridelements {
    .frame-default {
        margin-bottom: 0;
    }

    .container.grid-narrow {
        max-width: 300px;

        @include media-breakpoint-up(md) {
            max-width: 736px;
        }
    }
}

// - Space

.frame-space-after-none {
    margin-bottom: 0;
}

.frame-space-after-small {
    margin-bottom: 1.25rem;

    @include media-breakpoint-up(xl) {
        margin-bottom: 2.625rem;
    }
}

.frame-space-after-medium {
    margin-bottom: 2.5rem;

    @include media-breakpoint-up(lg) {
        margin-bottom: 5.625rem;
    }

    @include media-breakpoint-up(xl) {
        margin-bottom: 8.75rem;
    }
}

