.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #1e3466;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.1875rem;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1104px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^=top], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #e9ecef;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #262626;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: rgb(25.25, 25.25, 25.25);
  text-decoration: none;
  background-color: #faf7f2;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #fa4242;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #262626;
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #adb5bd;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #000;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #dee2e6 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #dee2e6;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #dee2e6;
  }
}
/* in critcal.scss
@if $enable-grid-classes {
    .container {
        @include make-container();
        @include make-container-max-widths();
    }
}
*/
.container-fluid {
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
  margin-right: auto;
  margin-left: auto;
}

/* in critical.scss
@if $enable-grid-classes {
    .row {
        @include make-row();
    }

    // Remove the negative margin from default .row, then the horizontal padding
    // from all immediate children columns (to prevent runaway style inheritance).
    .no-gutters {
        margin-right: 0;
        margin-left: 0;

        > .col,
        > [class*="col-"] {
            padding-right: 0;
            padding-left: 0;
        }
    }
}

 */
.col-xl,
.col-xl-auto, .col-xl-12, .col-xl-11, .col-xl-10, .col-xl-9, .col-xl-8, .col-xl-7, .col-xl-6, .col-xl-5, .col-xl-4, .col-xl-3, .col-xl-2, .col-xl-1, .col-lg,
.col-lg-auto, .col-lg-12, .col-lg-11, .col-lg-10, .col-lg-9, .col-lg-8, .col-lg-7, .col-lg-6, .col-lg-5, .col-lg-4, .col-lg-3, .col-lg-2, .col-lg-1, .col-md,
.col-md-auto, .col-md-12, .col-md-11, .col-md-10, .col-md-9, .col-md-8, .col-md-7, .col-md-6, .col-md-5, .col-md-4, .col-md-3, .col-md-2, .col-md-1, .col-sm,
.col-sm-auto, .col-sm-12, .col-sm-11, .col-sm-10, .col-sm-9, .col-sm-8, .col-sm-7, .col-sm-6, .col-sm-5, .col-sm-4, .col-sm-3, .col-sm-2, .col-sm-1, .col,
.col-auto, .col-12, .col-11, .col-10, .col-9, .col-8, .col-7, .col-6, .col-5, .col-4, .col-3, .col-2, .col-1 {
  position: relative;
  width: 100%;
  padding-right: 8px;
  padding-left: 8px;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.3333333333%;
  max-width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 16.6666666667%;
  max-width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333333333%;
  max-width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 41.6666666667%;
  max-width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333333333%;
  max-width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 66.6666666667%;
  max-width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333333333%;
  max-width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 91.6666666667%;
  max-width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.3333333333%;
  }
  .offset-md-2 {
    margin-left: 16.6666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.3333333333%;
  }
  .offset-md-5 {
    margin-left: 41.6666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.3333333333%;
  }
  .offset-md-8 {
    margin-left: 66.6666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.3333333333%;
  }
  .offset-md-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }
}
@media (min-width: 1104px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }
}
.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.bg-primary {
  background-color: #fa4242 !important;
}

a.bg-primary:hover, a.bg-primary:focus,
button.bg-primary:hover,
button.bg-primary:focus {
  background-color: rgb(248.6855670103, 16.3144329897, 16.3144329897) !important;
}

.bg-secondary {
  background-color: #1e3466 !important;
}

a.bg-secondary:hover, a.bg-secondary:focus,
button.bg-secondary:hover,
button.bg-secondary:focus {
  background-color: rgb(18.4090909091, 31.9090909091, 62.5909090909) !important;
}

.bg-light {
  background-color: #faf7f2 !important;
}

a.bg-light:hover, a.bg-light:focus,
button.bg-light:hover,
button.bg-light:focus {
  background-color: rgb(235.8333333333, 224.3333333333, 205.1666666667) !important;
}

.bg-dark {
  background-color: #262626 !important;
}

a.bg-dark:hover, a.bg-dark:focus,
button.bg-dark:hover,
button.bg-dark:focus {
  background-color: rgb(12.5, 12.5, 12.5) !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}
@media (min-width: 1104px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}
@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}
.embed-responsive {
  position: relative;
  display: block;
  width: 100%;
  padding: 0;
  overflow: hidden;
}
.embed-responsive::before {
  display: block;
  content: "";
}
.embed-responsive .embed-responsive-item,
.embed-responsive iframe,
.embed-responsive embed,
.embed-responsive object,
.embed-responsive video {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 0;
}

.embed-responsive-21by9::before {
  padding-top: 42.8571428571%;
}

.embed-responsive-16by9::before {
  padding-top: 56.25%;
}

.embed-responsive-4by3::before {
  padding-top: 75%;
}

.embed-responsive-1by1::before {
  padding-top: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

@media (min-width: 576px) {
  .flex-sm-row {
    flex-direction: row !important;
  }
  .flex-sm-column {
    flex-direction: column !important;
  }
  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }
  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-sm-fill {
    flex: 1 1 auto !important;
  }
  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-sm-start {
    justify-content: flex-start !important;
  }
  .justify-content-sm-end {
    justify-content: flex-end !important;
  }
  .justify-content-sm-center {
    justify-content: center !important;
  }
  .justify-content-sm-between {
    justify-content: space-between !important;
  }
  .justify-content-sm-around {
    justify-content: space-around !important;
  }
  .align-items-sm-start {
    align-items: flex-start !important;
  }
  .align-items-sm-end {
    align-items: flex-end !important;
  }
  .align-items-sm-center {
    align-items: center !important;
  }
  .align-items-sm-baseline {
    align-items: baseline !important;
  }
  .align-items-sm-stretch {
    align-items: stretch !important;
  }
  .align-content-sm-start {
    align-content: flex-start !important;
  }
  .align-content-sm-end {
    align-content: flex-end !important;
  }
  .align-content-sm-center {
    align-content: center !important;
  }
  .align-content-sm-between {
    align-content: space-between !important;
  }
  .align-content-sm-around {
    align-content: space-around !important;
  }
  .align-content-sm-stretch {
    align-content: stretch !important;
  }
  .align-self-sm-auto {
    align-self: auto !important;
  }
  .align-self-sm-start {
    align-self: flex-start !important;
  }
  .align-self-sm-end {
    align-self: flex-end !important;
  }
  .align-self-sm-center {
    align-self: center !important;
  }
  .align-self-sm-baseline {
    align-self: baseline !important;
  }
  .align-self-sm-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
  .flex-md-column {
    flex-direction: column !important;
  }
  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-md-wrap {
    flex-wrap: wrap !important;
  }
  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-md-fill {
    flex: 1 1 auto !important;
  }
  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-md-start {
    justify-content: flex-start !important;
  }
  .justify-content-md-end {
    justify-content: flex-end !important;
  }
  .justify-content-md-center {
    justify-content: center !important;
  }
  .justify-content-md-between {
    justify-content: space-between !important;
  }
  .justify-content-md-around {
    justify-content: space-around !important;
  }
  .align-items-md-start {
    align-items: flex-start !important;
  }
  .align-items-md-end {
    align-items: flex-end !important;
  }
  .align-items-md-center {
    align-items: center !important;
  }
  .align-items-md-baseline {
    align-items: baseline !important;
  }
  .align-items-md-stretch {
    align-items: stretch !important;
  }
  .align-content-md-start {
    align-content: flex-start !important;
  }
  .align-content-md-end {
    align-content: flex-end !important;
  }
  .align-content-md-center {
    align-content: center !important;
  }
  .align-content-md-between {
    align-content: space-between !important;
  }
  .align-content-md-around {
    align-content: space-around !important;
  }
  .align-content-md-stretch {
    align-content: stretch !important;
  }
  .align-self-md-auto {
    align-self: auto !important;
  }
  .align-self-md-start {
    align-self: flex-start !important;
  }
  .align-self-md-end {
    align-self: flex-end !important;
  }
  .align-self-md-center {
    align-self: center !important;
  }
  .align-self-md-baseline {
    align-self: baseline !important;
  }
  .align-self-md-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
  .flex-lg-column {
    flex-direction: column !important;
  }
  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }
  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-lg-fill {
    flex: 1 1 auto !important;
  }
  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-lg-start {
    justify-content: flex-start !important;
  }
  .justify-content-lg-end {
    justify-content: flex-end !important;
  }
  .justify-content-lg-center {
    justify-content: center !important;
  }
  .justify-content-lg-between {
    justify-content: space-between !important;
  }
  .justify-content-lg-around {
    justify-content: space-around !important;
  }
  .align-items-lg-start {
    align-items: flex-start !important;
  }
  .align-items-lg-end {
    align-items: flex-end !important;
  }
  .align-items-lg-center {
    align-items: center !important;
  }
  .align-items-lg-baseline {
    align-items: baseline !important;
  }
  .align-items-lg-stretch {
    align-items: stretch !important;
  }
  .align-content-lg-start {
    align-content: flex-start !important;
  }
  .align-content-lg-end {
    align-content: flex-end !important;
  }
  .align-content-lg-center {
    align-content: center !important;
  }
  .align-content-lg-between {
    align-content: space-between !important;
  }
  .align-content-lg-around {
    align-content: space-around !important;
  }
  .align-content-lg-stretch {
    align-content: stretch !important;
  }
  .align-self-lg-auto {
    align-self: auto !important;
  }
  .align-self-lg-start {
    align-self: flex-start !important;
  }
  .align-self-lg-end {
    align-self: flex-end !important;
  }
  .align-self-lg-center {
    align-self: center !important;
  }
  .align-self-lg-baseline {
    align-self: baseline !important;
  }
  .align-self-lg-stretch {
    align-self: stretch !important;
  }
}
@media (min-width: 1104px) {
  .flex-xl-row {
    flex-direction: row !important;
  }
  .flex-xl-column {
    flex-direction: column !important;
  }
  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }
  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }
  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }
  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }
  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }
  .flex-xl-fill {
    flex: 1 1 auto !important;
  }
  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }
  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }
  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }
  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }
  .justify-content-xl-start {
    justify-content: flex-start !important;
  }
  .justify-content-xl-end {
    justify-content: flex-end !important;
  }
  .justify-content-xl-center {
    justify-content: center !important;
  }
  .justify-content-xl-between {
    justify-content: space-between !important;
  }
  .justify-content-xl-around {
    justify-content: space-around !important;
  }
  .align-items-xl-start {
    align-items: flex-start !important;
  }
  .align-items-xl-end {
    align-items: flex-end !important;
  }
  .align-items-xl-center {
    align-items: center !important;
  }
  .align-items-xl-baseline {
    align-items: baseline !important;
  }
  .align-items-xl-stretch {
    align-items: stretch !important;
  }
  .align-content-xl-start {
    align-content: flex-start !important;
  }
  .align-content-xl-end {
    align-content: flex-end !important;
  }
  .align-content-xl-center {
    align-content: center !important;
  }
  .align-content-xl-between {
    align-content: space-between !important;
  }
  .align-content-xl-around {
    align-content: space-around !important;
  }
  .align-content-xl-stretch {
    align-content: stretch !important;
  }
  .align-self-xl-auto {
    align-self: auto !important;
  }
  .align-self-xl-start {
    align-self: flex-start !important;
  }
  .align-self-xl-end {
    align-self: flex-end !important;
  }
  .align-self-xl-center {
    align-self: center !important;
  }
  .align-self-xl-baseline {
    align-self: baseline !important;
  }
  .align-self-xl-stretch {
    align-self: stretch !important;
  }
}
.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.mh-100 {
  max-height: 100% !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.vw-100 {
  width: 100vw !important;
}

.vh-100 {
  height: 100vh !important;
}

.m-0 {
  margin: 0 !important;
}

.mt-0,
.my-0 {
  margin-top: 0 !important;
}

.mr-0,
.mx-0 {
  margin-right: 0 !important;
}

.mb-0,
.my-0 {
  margin-bottom: 0 !important;
}

.ml-0,
.mx-0 {
  margin-left: 0 !important;
}

.m-1 {
  margin: 0.5rem !important;
}

.mt-1,
.my-1 {
  margin-top: 0.5rem !important;
}

.mr-1,
.mx-1 {
  margin-right: 0.5rem !important;
}

.mb-1,
.my-1 {
  margin-bottom: 0.5rem !important;
}

.ml-1,
.mx-1 {
  margin-left: 0.5rem !important;
}

.m-2 {
  margin: 1rem !important;
}

.mt-2,
.my-2 {
  margin-top: 1rem !important;
}

.mr-2,
.mx-2 {
  margin-right: 1rem !important;
}

.mb-2,
.my-2 {
  margin-bottom: 1rem !important;
}

.ml-2,
.mx-2 {
  margin-left: 1rem !important;
}

.m-3 {
  margin: 1.5rem !important;
}

.mt-3,
.my-3 {
  margin-top: 1.5rem !important;
}

.mr-3,
.mx-3 {
  margin-right: 1.5rem !important;
}

.mb-3,
.my-3 {
  margin-bottom: 1.5rem !important;
}

.ml-3,
.mx-3 {
  margin-left: 1.5rem !important;
}

.m-4 {
  margin: 3rem !important;
}

.mt-4,
.my-4 {
  margin-top: 3rem !important;
}

.mr-4,
.mx-4 {
  margin-right: 3rem !important;
}

.mb-4,
.my-4 {
  margin-bottom: 3rem !important;
}

.ml-4,
.mx-4 {
  margin-left: 3rem !important;
}

.m-5 {
  margin: 4rem !important;
}

.mt-5,
.my-5 {
  margin-top: 4rem !important;
}

.mr-5,
.mx-5 {
  margin-right: 4rem !important;
}

.mb-5,
.my-5 {
  margin-bottom: 4rem !important;
}

.ml-5,
.mx-5 {
  margin-left: 4rem !important;
}

.p-0 {
  padding: 0 !important;
}

.pt-0,
.py-0 {
  padding-top: 0 !important;
}

.pr-0,
.px-0 {
  padding-right: 0 !important;
}

.pb-0,
.py-0 {
  padding-bottom: 0 !important;
}

.pl-0,
.px-0 {
  padding-left: 0 !important;
}

.p-1 {
  padding: 0.5rem !important;
}

.pt-1,
.py-1 {
  padding-top: 0.5rem !important;
}

.pr-1,
.px-1 {
  padding-right: 0.5rem !important;
}

.pb-1,
.py-1 {
  padding-bottom: 0.5rem !important;
}

.pl-1,
.px-1 {
  padding-left: 0.5rem !important;
}

.p-2 {
  padding: 1rem !important;
}

.pt-2,
.py-2 {
  padding-top: 1rem !important;
}

.pr-2,
.px-2 {
  padding-right: 1rem !important;
}

.pb-2,
.py-2 {
  padding-bottom: 1rem !important;
}

.pl-2,
.px-2 {
  padding-left: 1rem !important;
}

.p-3 {
  padding: 1.5rem !important;
}

.pt-3,
.py-3 {
  padding-top: 1.5rem !important;
}

.pr-3,
.px-3 {
  padding-right: 1.5rem !important;
}

.pb-3,
.py-3 {
  padding-bottom: 1.5rem !important;
}

.pl-3,
.px-3 {
  padding-left: 1.5rem !important;
}

.p-4 {
  padding: 3rem !important;
}

.pt-4,
.py-4 {
  padding-top: 3rem !important;
}

.pr-4,
.px-4 {
  padding-right: 3rem !important;
}

.pb-4,
.py-4 {
  padding-bottom: 3rem !important;
}

.pl-4,
.px-4 {
  padding-left: 3rem !important;
}

.p-5 {
  padding: 4rem !important;
}

.pt-5,
.py-5 {
  padding-top: 4rem !important;
}

.pr-5,
.px-5 {
  padding-right: 4rem !important;
}

.pb-5,
.py-5 {
  padding-bottom: 4rem !important;
}

.pl-5,
.px-5 {
  padding-left: 4rem !important;
}

.m-n1 {
  margin: -0.5rem !important;
}

.mt-n1,
.my-n1 {
  margin-top: -0.5rem !important;
}

.mr-n1,
.mx-n1 {
  margin-right: -0.5rem !important;
}

.mb-n1,
.my-n1 {
  margin-bottom: -0.5rem !important;
}

.ml-n1,
.mx-n1 {
  margin-left: -0.5rem !important;
}

.m-n2 {
  margin: -1rem !important;
}

.mt-n2,
.my-n2 {
  margin-top: -1rem !important;
}

.mr-n2,
.mx-n2 {
  margin-right: -1rem !important;
}

.mb-n2,
.my-n2 {
  margin-bottom: -1rem !important;
}

.ml-n2,
.mx-n2 {
  margin-left: -1rem !important;
}

.m-n3 {
  margin: -1.5rem !important;
}

.mt-n3,
.my-n3 {
  margin-top: -1.5rem !important;
}

.mr-n3,
.mx-n3 {
  margin-right: -1.5rem !important;
}

.mb-n3,
.my-n3 {
  margin-bottom: -1.5rem !important;
}

.ml-n3,
.mx-n3 {
  margin-left: -1.5rem !important;
}

.m-n4 {
  margin: -3rem !important;
}

.mt-n4,
.my-n4 {
  margin-top: -3rem !important;
}

.mr-n4,
.mx-n4 {
  margin-right: -3rem !important;
}

.mb-n4,
.my-n4 {
  margin-bottom: -3rem !important;
}

.ml-n4,
.mx-n4 {
  margin-left: -3rem !important;
}

.m-n5 {
  margin: -4rem !important;
}

.mt-n5,
.my-n5 {
  margin-top: -4rem !important;
}

.mr-n5,
.mx-n5 {
  margin-right: -4rem !important;
}

.mb-n5,
.my-n5 {
  margin-bottom: -4rem !important;
}

.ml-n5,
.mx-n5 {
  margin-left: -4rem !important;
}

.m-auto {
  margin: auto !important;
}

.mt-auto,
.my-auto {
  margin-top: auto !important;
}

.mr-auto,
.mx-auto {
  margin-right: auto !important;
}

.mb-auto,
.my-auto {
  margin-bottom: auto !important;
}

.ml-auto,
.mx-auto {
  margin-left: auto !important;
}

@media (min-width: 576px) {
  .m-sm-0 {
    margin: 0 !important;
  }
  .mt-sm-0,
  .my-sm-0 {
    margin-top: 0 !important;
  }
  .mr-sm-0,
  .mx-sm-0 {
    margin-right: 0 !important;
  }
  .mb-sm-0,
  .my-sm-0 {
    margin-bottom: 0 !important;
  }
  .ml-sm-0,
  .mx-sm-0 {
    margin-left: 0 !important;
  }
  .m-sm-1 {
    margin: 0.5rem !important;
  }
  .mt-sm-1,
  .my-sm-1 {
    margin-top: 0.5rem !important;
  }
  .mr-sm-1,
  .mx-sm-1 {
    margin-right: 0.5rem !important;
  }
  .mb-sm-1,
  .my-sm-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-sm-1,
  .mx-sm-1 {
    margin-left: 0.5rem !important;
  }
  .m-sm-2 {
    margin: 1rem !important;
  }
  .mt-sm-2,
  .my-sm-2 {
    margin-top: 1rem !important;
  }
  .mr-sm-2,
  .mx-sm-2 {
    margin-right: 1rem !important;
  }
  .mb-sm-2,
  .my-sm-2 {
    margin-bottom: 1rem !important;
  }
  .ml-sm-2,
  .mx-sm-2 {
    margin-left: 1rem !important;
  }
  .m-sm-3 {
    margin: 1.5rem !important;
  }
  .mt-sm-3,
  .my-sm-3 {
    margin-top: 1.5rem !important;
  }
  .mr-sm-3,
  .mx-sm-3 {
    margin-right: 1.5rem !important;
  }
  .mb-sm-3,
  .my-sm-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-sm-3,
  .mx-sm-3 {
    margin-left: 1.5rem !important;
  }
  .m-sm-4 {
    margin: 3rem !important;
  }
  .mt-sm-4,
  .my-sm-4 {
    margin-top: 3rem !important;
  }
  .mr-sm-4,
  .mx-sm-4 {
    margin-right: 3rem !important;
  }
  .mb-sm-4,
  .my-sm-4 {
    margin-bottom: 3rem !important;
  }
  .ml-sm-4,
  .mx-sm-4 {
    margin-left: 3rem !important;
  }
  .m-sm-5 {
    margin: 4rem !important;
  }
  .mt-sm-5,
  .my-sm-5 {
    margin-top: 4rem !important;
  }
  .mr-sm-5,
  .mx-sm-5 {
    margin-right: 4rem !important;
  }
  .mb-sm-5,
  .my-sm-5 {
    margin-bottom: 4rem !important;
  }
  .ml-sm-5,
  .mx-sm-5 {
    margin-left: 4rem !important;
  }
  .p-sm-0 {
    padding: 0 !important;
  }
  .pt-sm-0,
  .py-sm-0 {
    padding-top: 0 !important;
  }
  .pr-sm-0,
  .px-sm-0 {
    padding-right: 0 !important;
  }
  .pb-sm-0,
  .py-sm-0 {
    padding-bottom: 0 !important;
  }
  .pl-sm-0,
  .px-sm-0 {
    padding-left: 0 !important;
  }
  .p-sm-1 {
    padding: 0.5rem !important;
  }
  .pt-sm-1,
  .py-sm-1 {
    padding-top: 0.5rem !important;
  }
  .pr-sm-1,
  .px-sm-1 {
    padding-right: 0.5rem !important;
  }
  .pb-sm-1,
  .py-sm-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-sm-1,
  .px-sm-1 {
    padding-left: 0.5rem !important;
  }
  .p-sm-2 {
    padding: 1rem !important;
  }
  .pt-sm-2,
  .py-sm-2 {
    padding-top: 1rem !important;
  }
  .pr-sm-2,
  .px-sm-2 {
    padding-right: 1rem !important;
  }
  .pb-sm-2,
  .py-sm-2 {
    padding-bottom: 1rem !important;
  }
  .pl-sm-2,
  .px-sm-2 {
    padding-left: 1rem !important;
  }
  .p-sm-3 {
    padding: 1.5rem !important;
  }
  .pt-sm-3,
  .py-sm-3 {
    padding-top: 1.5rem !important;
  }
  .pr-sm-3,
  .px-sm-3 {
    padding-right: 1.5rem !important;
  }
  .pb-sm-3,
  .py-sm-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-sm-3,
  .px-sm-3 {
    padding-left: 1.5rem !important;
  }
  .p-sm-4 {
    padding: 3rem !important;
  }
  .pt-sm-4,
  .py-sm-4 {
    padding-top: 3rem !important;
  }
  .pr-sm-4,
  .px-sm-4 {
    padding-right: 3rem !important;
  }
  .pb-sm-4,
  .py-sm-4 {
    padding-bottom: 3rem !important;
  }
  .pl-sm-4,
  .px-sm-4 {
    padding-left: 3rem !important;
  }
  .p-sm-5 {
    padding: 4rem !important;
  }
  .pt-sm-5,
  .py-sm-5 {
    padding-top: 4rem !important;
  }
  .pr-sm-5,
  .px-sm-5 {
    padding-right: 4rem !important;
  }
  .pb-sm-5,
  .py-sm-5 {
    padding-bottom: 4rem !important;
  }
  .pl-sm-5,
  .px-sm-5 {
    padding-left: 4rem !important;
  }
  .m-sm-n1 {
    margin: -0.5rem !important;
  }
  .mt-sm-n1,
  .my-sm-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-sm-n1,
  .mx-sm-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-sm-n1,
  .my-sm-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-sm-n1,
  .mx-sm-n1 {
    margin-left: -0.5rem !important;
  }
  .m-sm-n2 {
    margin: -1rem !important;
  }
  .mt-sm-n2,
  .my-sm-n2 {
    margin-top: -1rem !important;
  }
  .mr-sm-n2,
  .mx-sm-n2 {
    margin-right: -1rem !important;
  }
  .mb-sm-n2,
  .my-sm-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-sm-n2,
  .mx-sm-n2 {
    margin-left: -1rem !important;
  }
  .m-sm-n3 {
    margin: -1.5rem !important;
  }
  .mt-sm-n3,
  .my-sm-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-sm-n3,
  .mx-sm-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-sm-n3,
  .my-sm-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-sm-n3,
  .mx-sm-n3 {
    margin-left: -1.5rem !important;
  }
  .m-sm-n4 {
    margin: -3rem !important;
  }
  .mt-sm-n4,
  .my-sm-n4 {
    margin-top: -3rem !important;
  }
  .mr-sm-n4,
  .mx-sm-n4 {
    margin-right: -3rem !important;
  }
  .mb-sm-n4,
  .my-sm-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-sm-n4,
  .mx-sm-n4 {
    margin-left: -3rem !important;
  }
  .m-sm-n5 {
    margin: -4rem !important;
  }
  .mt-sm-n5,
  .my-sm-n5 {
    margin-top: -4rem !important;
  }
  .mr-sm-n5,
  .mx-sm-n5 {
    margin-right: -4rem !important;
  }
  .mb-sm-n5,
  .my-sm-n5 {
    margin-bottom: -4rem !important;
  }
  .ml-sm-n5,
  .mx-sm-n5 {
    margin-left: -4rem !important;
  }
  .m-sm-auto {
    margin: auto !important;
  }
  .mt-sm-auto,
  .my-sm-auto {
    margin-top: auto !important;
  }
  .mr-sm-auto,
  .mx-sm-auto {
    margin-right: auto !important;
  }
  .mb-sm-auto,
  .my-sm-auto {
    margin-bottom: auto !important;
  }
  .ml-sm-auto,
  .mx-sm-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 768px) {
  .m-md-0 {
    margin: 0 !important;
  }
  .mt-md-0,
  .my-md-0 {
    margin-top: 0 !important;
  }
  .mr-md-0,
  .mx-md-0 {
    margin-right: 0 !important;
  }
  .mb-md-0,
  .my-md-0 {
    margin-bottom: 0 !important;
  }
  .ml-md-0,
  .mx-md-0 {
    margin-left: 0 !important;
  }
  .m-md-1 {
    margin: 0.5rem !important;
  }
  .mt-md-1,
  .my-md-1 {
    margin-top: 0.5rem !important;
  }
  .mr-md-1,
  .mx-md-1 {
    margin-right: 0.5rem !important;
  }
  .mb-md-1,
  .my-md-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-md-1,
  .mx-md-1 {
    margin-left: 0.5rem !important;
  }
  .m-md-2 {
    margin: 1rem !important;
  }
  .mt-md-2,
  .my-md-2 {
    margin-top: 1rem !important;
  }
  .mr-md-2,
  .mx-md-2 {
    margin-right: 1rem !important;
  }
  .mb-md-2,
  .my-md-2 {
    margin-bottom: 1rem !important;
  }
  .ml-md-2,
  .mx-md-2 {
    margin-left: 1rem !important;
  }
  .m-md-3 {
    margin: 1.5rem !important;
  }
  .mt-md-3,
  .my-md-3 {
    margin-top: 1.5rem !important;
  }
  .mr-md-3,
  .mx-md-3 {
    margin-right: 1.5rem !important;
  }
  .mb-md-3,
  .my-md-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-md-3,
  .mx-md-3 {
    margin-left: 1.5rem !important;
  }
  .m-md-4 {
    margin: 3rem !important;
  }
  .mt-md-4,
  .my-md-4 {
    margin-top: 3rem !important;
  }
  .mr-md-4,
  .mx-md-4 {
    margin-right: 3rem !important;
  }
  .mb-md-4,
  .my-md-4 {
    margin-bottom: 3rem !important;
  }
  .ml-md-4,
  .mx-md-4 {
    margin-left: 3rem !important;
  }
  .m-md-5 {
    margin: 4rem !important;
  }
  .mt-md-5,
  .my-md-5 {
    margin-top: 4rem !important;
  }
  .mr-md-5,
  .mx-md-5 {
    margin-right: 4rem !important;
  }
  .mb-md-5,
  .my-md-5 {
    margin-bottom: 4rem !important;
  }
  .ml-md-5,
  .mx-md-5 {
    margin-left: 4rem !important;
  }
  .p-md-0 {
    padding: 0 !important;
  }
  .pt-md-0,
  .py-md-0 {
    padding-top: 0 !important;
  }
  .pr-md-0,
  .px-md-0 {
    padding-right: 0 !important;
  }
  .pb-md-0,
  .py-md-0 {
    padding-bottom: 0 !important;
  }
  .pl-md-0,
  .px-md-0 {
    padding-left: 0 !important;
  }
  .p-md-1 {
    padding: 0.5rem !important;
  }
  .pt-md-1,
  .py-md-1 {
    padding-top: 0.5rem !important;
  }
  .pr-md-1,
  .px-md-1 {
    padding-right: 0.5rem !important;
  }
  .pb-md-1,
  .py-md-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-md-1,
  .px-md-1 {
    padding-left: 0.5rem !important;
  }
  .p-md-2 {
    padding: 1rem !important;
  }
  .pt-md-2,
  .py-md-2 {
    padding-top: 1rem !important;
  }
  .pr-md-2,
  .px-md-2 {
    padding-right: 1rem !important;
  }
  .pb-md-2,
  .py-md-2 {
    padding-bottom: 1rem !important;
  }
  .pl-md-2,
  .px-md-2 {
    padding-left: 1rem !important;
  }
  .p-md-3 {
    padding: 1.5rem !important;
  }
  .pt-md-3,
  .py-md-3 {
    padding-top: 1.5rem !important;
  }
  .pr-md-3,
  .px-md-3 {
    padding-right: 1.5rem !important;
  }
  .pb-md-3,
  .py-md-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-md-3,
  .px-md-3 {
    padding-left: 1.5rem !important;
  }
  .p-md-4 {
    padding: 3rem !important;
  }
  .pt-md-4,
  .py-md-4 {
    padding-top: 3rem !important;
  }
  .pr-md-4,
  .px-md-4 {
    padding-right: 3rem !important;
  }
  .pb-md-4,
  .py-md-4 {
    padding-bottom: 3rem !important;
  }
  .pl-md-4,
  .px-md-4 {
    padding-left: 3rem !important;
  }
  .p-md-5 {
    padding: 4rem !important;
  }
  .pt-md-5,
  .py-md-5 {
    padding-top: 4rem !important;
  }
  .pr-md-5,
  .px-md-5 {
    padding-right: 4rem !important;
  }
  .pb-md-5,
  .py-md-5 {
    padding-bottom: 4rem !important;
  }
  .pl-md-5,
  .px-md-5 {
    padding-left: 4rem !important;
  }
  .m-md-n1 {
    margin: -0.5rem !important;
  }
  .mt-md-n1,
  .my-md-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-md-n1,
  .mx-md-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-md-n1,
  .my-md-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-md-n1,
  .mx-md-n1 {
    margin-left: -0.5rem !important;
  }
  .m-md-n2 {
    margin: -1rem !important;
  }
  .mt-md-n2,
  .my-md-n2 {
    margin-top: -1rem !important;
  }
  .mr-md-n2,
  .mx-md-n2 {
    margin-right: -1rem !important;
  }
  .mb-md-n2,
  .my-md-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-md-n2,
  .mx-md-n2 {
    margin-left: -1rem !important;
  }
  .m-md-n3 {
    margin: -1.5rem !important;
  }
  .mt-md-n3,
  .my-md-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-md-n3,
  .mx-md-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-md-n3,
  .my-md-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-md-n3,
  .mx-md-n3 {
    margin-left: -1.5rem !important;
  }
  .m-md-n4 {
    margin: -3rem !important;
  }
  .mt-md-n4,
  .my-md-n4 {
    margin-top: -3rem !important;
  }
  .mr-md-n4,
  .mx-md-n4 {
    margin-right: -3rem !important;
  }
  .mb-md-n4,
  .my-md-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-md-n4,
  .mx-md-n4 {
    margin-left: -3rem !important;
  }
  .m-md-n5 {
    margin: -4rem !important;
  }
  .mt-md-n5,
  .my-md-n5 {
    margin-top: -4rem !important;
  }
  .mr-md-n5,
  .mx-md-n5 {
    margin-right: -4rem !important;
  }
  .mb-md-n5,
  .my-md-n5 {
    margin-bottom: -4rem !important;
  }
  .ml-md-n5,
  .mx-md-n5 {
    margin-left: -4rem !important;
  }
  .m-md-auto {
    margin: auto !important;
  }
  .mt-md-auto,
  .my-md-auto {
    margin-top: auto !important;
  }
  .mr-md-auto,
  .mx-md-auto {
    margin-right: auto !important;
  }
  .mb-md-auto,
  .my-md-auto {
    margin-bottom: auto !important;
  }
  .ml-md-auto,
  .mx-md-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 992px) {
  .m-lg-0 {
    margin: 0 !important;
  }
  .mt-lg-0,
  .my-lg-0 {
    margin-top: 0 !important;
  }
  .mr-lg-0,
  .mx-lg-0 {
    margin-right: 0 !important;
  }
  .mb-lg-0,
  .my-lg-0 {
    margin-bottom: 0 !important;
  }
  .ml-lg-0,
  .mx-lg-0 {
    margin-left: 0 !important;
  }
  .m-lg-1 {
    margin: 0.5rem !important;
  }
  .mt-lg-1,
  .my-lg-1 {
    margin-top: 0.5rem !important;
  }
  .mr-lg-1,
  .mx-lg-1 {
    margin-right: 0.5rem !important;
  }
  .mb-lg-1,
  .my-lg-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-lg-1,
  .mx-lg-1 {
    margin-left: 0.5rem !important;
  }
  .m-lg-2 {
    margin: 1rem !important;
  }
  .mt-lg-2,
  .my-lg-2 {
    margin-top: 1rem !important;
  }
  .mr-lg-2,
  .mx-lg-2 {
    margin-right: 1rem !important;
  }
  .mb-lg-2,
  .my-lg-2 {
    margin-bottom: 1rem !important;
  }
  .ml-lg-2,
  .mx-lg-2 {
    margin-left: 1rem !important;
  }
  .m-lg-3 {
    margin: 1.5rem !important;
  }
  .mt-lg-3,
  .my-lg-3 {
    margin-top: 1.5rem !important;
  }
  .mr-lg-3,
  .mx-lg-3 {
    margin-right: 1.5rem !important;
  }
  .mb-lg-3,
  .my-lg-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-lg-3,
  .mx-lg-3 {
    margin-left: 1.5rem !important;
  }
  .m-lg-4 {
    margin: 3rem !important;
  }
  .mt-lg-4,
  .my-lg-4 {
    margin-top: 3rem !important;
  }
  .mr-lg-4,
  .mx-lg-4 {
    margin-right: 3rem !important;
  }
  .mb-lg-4,
  .my-lg-4 {
    margin-bottom: 3rem !important;
  }
  .ml-lg-4,
  .mx-lg-4 {
    margin-left: 3rem !important;
  }
  .m-lg-5 {
    margin: 4rem !important;
  }
  .mt-lg-5,
  .my-lg-5 {
    margin-top: 4rem !important;
  }
  .mr-lg-5,
  .mx-lg-5 {
    margin-right: 4rem !important;
  }
  .mb-lg-5,
  .my-lg-5 {
    margin-bottom: 4rem !important;
  }
  .ml-lg-5,
  .mx-lg-5 {
    margin-left: 4rem !important;
  }
  .p-lg-0 {
    padding: 0 !important;
  }
  .pt-lg-0,
  .py-lg-0 {
    padding-top: 0 !important;
  }
  .pr-lg-0,
  .px-lg-0 {
    padding-right: 0 !important;
  }
  .pb-lg-0,
  .py-lg-0 {
    padding-bottom: 0 !important;
  }
  .pl-lg-0,
  .px-lg-0 {
    padding-left: 0 !important;
  }
  .p-lg-1 {
    padding: 0.5rem !important;
  }
  .pt-lg-1,
  .py-lg-1 {
    padding-top: 0.5rem !important;
  }
  .pr-lg-1,
  .px-lg-1 {
    padding-right: 0.5rem !important;
  }
  .pb-lg-1,
  .py-lg-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-lg-1,
  .px-lg-1 {
    padding-left: 0.5rem !important;
  }
  .p-lg-2 {
    padding: 1rem !important;
  }
  .pt-lg-2,
  .py-lg-2 {
    padding-top: 1rem !important;
  }
  .pr-lg-2,
  .px-lg-2 {
    padding-right: 1rem !important;
  }
  .pb-lg-2,
  .py-lg-2 {
    padding-bottom: 1rem !important;
  }
  .pl-lg-2,
  .px-lg-2 {
    padding-left: 1rem !important;
  }
  .p-lg-3 {
    padding: 1.5rem !important;
  }
  .pt-lg-3,
  .py-lg-3 {
    padding-top: 1.5rem !important;
  }
  .pr-lg-3,
  .px-lg-3 {
    padding-right: 1.5rem !important;
  }
  .pb-lg-3,
  .py-lg-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-lg-3,
  .px-lg-3 {
    padding-left: 1.5rem !important;
  }
  .p-lg-4 {
    padding: 3rem !important;
  }
  .pt-lg-4,
  .py-lg-4 {
    padding-top: 3rem !important;
  }
  .pr-lg-4,
  .px-lg-4 {
    padding-right: 3rem !important;
  }
  .pb-lg-4,
  .py-lg-4 {
    padding-bottom: 3rem !important;
  }
  .pl-lg-4,
  .px-lg-4 {
    padding-left: 3rem !important;
  }
  .p-lg-5 {
    padding: 4rem !important;
  }
  .pt-lg-5,
  .py-lg-5 {
    padding-top: 4rem !important;
  }
  .pr-lg-5,
  .px-lg-5 {
    padding-right: 4rem !important;
  }
  .pb-lg-5,
  .py-lg-5 {
    padding-bottom: 4rem !important;
  }
  .pl-lg-5,
  .px-lg-5 {
    padding-left: 4rem !important;
  }
  .m-lg-n1 {
    margin: -0.5rem !important;
  }
  .mt-lg-n1,
  .my-lg-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-lg-n1,
  .mx-lg-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-lg-n1,
  .my-lg-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-lg-n1,
  .mx-lg-n1 {
    margin-left: -0.5rem !important;
  }
  .m-lg-n2 {
    margin: -1rem !important;
  }
  .mt-lg-n2,
  .my-lg-n2 {
    margin-top: -1rem !important;
  }
  .mr-lg-n2,
  .mx-lg-n2 {
    margin-right: -1rem !important;
  }
  .mb-lg-n2,
  .my-lg-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-lg-n2,
  .mx-lg-n2 {
    margin-left: -1rem !important;
  }
  .m-lg-n3 {
    margin: -1.5rem !important;
  }
  .mt-lg-n3,
  .my-lg-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-lg-n3,
  .mx-lg-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-lg-n3,
  .my-lg-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-lg-n3,
  .mx-lg-n3 {
    margin-left: -1.5rem !important;
  }
  .m-lg-n4 {
    margin: -3rem !important;
  }
  .mt-lg-n4,
  .my-lg-n4 {
    margin-top: -3rem !important;
  }
  .mr-lg-n4,
  .mx-lg-n4 {
    margin-right: -3rem !important;
  }
  .mb-lg-n4,
  .my-lg-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-lg-n4,
  .mx-lg-n4 {
    margin-left: -3rem !important;
  }
  .m-lg-n5 {
    margin: -4rem !important;
  }
  .mt-lg-n5,
  .my-lg-n5 {
    margin-top: -4rem !important;
  }
  .mr-lg-n5,
  .mx-lg-n5 {
    margin-right: -4rem !important;
  }
  .mb-lg-n5,
  .my-lg-n5 {
    margin-bottom: -4rem !important;
  }
  .ml-lg-n5,
  .mx-lg-n5 {
    margin-left: -4rem !important;
  }
  .m-lg-auto {
    margin: auto !important;
  }
  .mt-lg-auto,
  .my-lg-auto {
    margin-top: auto !important;
  }
  .mr-lg-auto,
  .mx-lg-auto {
    margin-right: auto !important;
  }
  .mb-lg-auto,
  .my-lg-auto {
    margin-bottom: auto !important;
  }
  .ml-lg-auto,
  .mx-lg-auto {
    margin-left: auto !important;
  }
}
@media (min-width: 1104px) {
  .m-xl-0 {
    margin: 0 !important;
  }
  .mt-xl-0,
  .my-xl-0 {
    margin-top: 0 !important;
  }
  .mr-xl-0,
  .mx-xl-0 {
    margin-right: 0 !important;
  }
  .mb-xl-0,
  .my-xl-0 {
    margin-bottom: 0 !important;
  }
  .ml-xl-0,
  .mx-xl-0 {
    margin-left: 0 !important;
  }
  .m-xl-1 {
    margin: 0.5rem !important;
  }
  .mt-xl-1,
  .my-xl-1 {
    margin-top: 0.5rem !important;
  }
  .mr-xl-1,
  .mx-xl-1 {
    margin-right: 0.5rem !important;
  }
  .mb-xl-1,
  .my-xl-1 {
    margin-bottom: 0.5rem !important;
  }
  .ml-xl-1,
  .mx-xl-1 {
    margin-left: 0.5rem !important;
  }
  .m-xl-2 {
    margin: 1rem !important;
  }
  .mt-xl-2,
  .my-xl-2 {
    margin-top: 1rem !important;
  }
  .mr-xl-2,
  .mx-xl-2 {
    margin-right: 1rem !important;
  }
  .mb-xl-2,
  .my-xl-2 {
    margin-bottom: 1rem !important;
  }
  .ml-xl-2,
  .mx-xl-2 {
    margin-left: 1rem !important;
  }
  .m-xl-3 {
    margin: 1.5rem !important;
  }
  .mt-xl-3,
  .my-xl-3 {
    margin-top: 1.5rem !important;
  }
  .mr-xl-3,
  .mx-xl-3 {
    margin-right: 1.5rem !important;
  }
  .mb-xl-3,
  .my-xl-3 {
    margin-bottom: 1.5rem !important;
  }
  .ml-xl-3,
  .mx-xl-3 {
    margin-left: 1.5rem !important;
  }
  .m-xl-4 {
    margin: 3rem !important;
  }
  .mt-xl-4,
  .my-xl-4 {
    margin-top: 3rem !important;
  }
  .mr-xl-4,
  .mx-xl-4 {
    margin-right: 3rem !important;
  }
  .mb-xl-4,
  .my-xl-4 {
    margin-bottom: 3rem !important;
  }
  .ml-xl-4,
  .mx-xl-4 {
    margin-left: 3rem !important;
  }
  .m-xl-5 {
    margin: 4rem !important;
  }
  .mt-xl-5,
  .my-xl-5 {
    margin-top: 4rem !important;
  }
  .mr-xl-5,
  .mx-xl-5 {
    margin-right: 4rem !important;
  }
  .mb-xl-5,
  .my-xl-5 {
    margin-bottom: 4rem !important;
  }
  .ml-xl-5,
  .mx-xl-5 {
    margin-left: 4rem !important;
  }
  .p-xl-0 {
    padding: 0 !important;
  }
  .pt-xl-0,
  .py-xl-0 {
    padding-top: 0 !important;
  }
  .pr-xl-0,
  .px-xl-0 {
    padding-right: 0 !important;
  }
  .pb-xl-0,
  .py-xl-0 {
    padding-bottom: 0 !important;
  }
  .pl-xl-0,
  .px-xl-0 {
    padding-left: 0 !important;
  }
  .p-xl-1 {
    padding: 0.5rem !important;
  }
  .pt-xl-1,
  .py-xl-1 {
    padding-top: 0.5rem !important;
  }
  .pr-xl-1,
  .px-xl-1 {
    padding-right: 0.5rem !important;
  }
  .pb-xl-1,
  .py-xl-1 {
    padding-bottom: 0.5rem !important;
  }
  .pl-xl-1,
  .px-xl-1 {
    padding-left: 0.5rem !important;
  }
  .p-xl-2 {
    padding: 1rem !important;
  }
  .pt-xl-2,
  .py-xl-2 {
    padding-top: 1rem !important;
  }
  .pr-xl-2,
  .px-xl-2 {
    padding-right: 1rem !important;
  }
  .pb-xl-2,
  .py-xl-2 {
    padding-bottom: 1rem !important;
  }
  .pl-xl-2,
  .px-xl-2 {
    padding-left: 1rem !important;
  }
  .p-xl-3 {
    padding: 1.5rem !important;
  }
  .pt-xl-3,
  .py-xl-3 {
    padding-top: 1.5rem !important;
  }
  .pr-xl-3,
  .px-xl-3 {
    padding-right: 1.5rem !important;
  }
  .pb-xl-3,
  .py-xl-3 {
    padding-bottom: 1.5rem !important;
  }
  .pl-xl-3,
  .px-xl-3 {
    padding-left: 1.5rem !important;
  }
  .p-xl-4 {
    padding: 3rem !important;
  }
  .pt-xl-4,
  .py-xl-4 {
    padding-top: 3rem !important;
  }
  .pr-xl-4,
  .px-xl-4 {
    padding-right: 3rem !important;
  }
  .pb-xl-4,
  .py-xl-4 {
    padding-bottom: 3rem !important;
  }
  .pl-xl-4,
  .px-xl-4 {
    padding-left: 3rem !important;
  }
  .p-xl-5 {
    padding: 4rem !important;
  }
  .pt-xl-5,
  .py-xl-5 {
    padding-top: 4rem !important;
  }
  .pr-xl-5,
  .px-xl-5 {
    padding-right: 4rem !important;
  }
  .pb-xl-5,
  .py-xl-5 {
    padding-bottom: 4rem !important;
  }
  .pl-xl-5,
  .px-xl-5 {
    padding-left: 4rem !important;
  }
  .m-xl-n1 {
    margin: -0.5rem !important;
  }
  .mt-xl-n1,
  .my-xl-n1 {
    margin-top: -0.5rem !important;
  }
  .mr-xl-n1,
  .mx-xl-n1 {
    margin-right: -0.5rem !important;
  }
  .mb-xl-n1,
  .my-xl-n1 {
    margin-bottom: -0.5rem !important;
  }
  .ml-xl-n1,
  .mx-xl-n1 {
    margin-left: -0.5rem !important;
  }
  .m-xl-n2 {
    margin: -1rem !important;
  }
  .mt-xl-n2,
  .my-xl-n2 {
    margin-top: -1rem !important;
  }
  .mr-xl-n2,
  .mx-xl-n2 {
    margin-right: -1rem !important;
  }
  .mb-xl-n2,
  .my-xl-n2 {
    margin-bottom: -1rem !important;
  }
  .ml-xl-n2,
  .mx-xl-n2 {
    margin-left: -1rem !important;
  }
  .m-xl-n3 {
    margin: -1.5rem !important;
  }
  .mt-xl-n3,
  .my-xl-n3 {
    margin-top: -1.5rem !important;
  }
  .mr-xl-n3,
  .mx-xl-n3 {
    margin-right: -1.5rem !important;
  }
  .mb-xl-n3,
  .my-xl-n3 {
    margin-bottom: -1.5rem !important;
  }
  .ml-xl-n3,
  .mx-xl-n3 {
    margin-left: -1.5rem !important;
  }
  .m-xl-n4 {
    margin: -3rem !important;
  }
  .mt-xl-n4,
  .my-xl-n4 {
    margin-top: -3rem !important;
  }
  .mr-xl-n4,
  .mx-xl-n4 {
    margin-right: -3rem !important;
  }
  .mb-xl-n4,
  .my-xl-n4 {
    margin-bottom: -3rem !important;
  }
  .ml-xl-n4,
  .mx-xl-n4 {
    margin-left: -3rem !important;
  }
  .m-xl-n5 {
    margin: -4rem !important;
  }
  .mt-xl-n5,
  .my-xl-n5 {
    margin-top: -4rem !important;
  }
  .mr-xl-n5,
  .mx-xl-n5 {
    margin-right: -4rem !important;
  }
  .mb-xl-n5,
  .my-xl-n5 {
    margin-bottom: -4rem !important;
  }
  .ml-xl-n5,
  .mx-xl-n5 {
    margin-left: -4rem !important;
  }
  .m-xl-auto {
    margin: auto !important;
  }
  .mt-xl-auto,
  .my-xl-auto {
    margin-top: auto !important;
  }
  .mr-xl-auto,
  .mx-xl-auto {
    margin-right: auto !important;
  }
  .mb-xl-auto,
  .my-xl-auto {
    margin-bottom: auto !important;
  }
  .ml-xl-auto,
  .mx-xl-auto {
    margin-left: auto !important;
  }
}
.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1104px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}
.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: 700 !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #fa4242 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: rgb(233.3273195876, 6.1726804124, 6.1726804124) !important;
}

.text-secondary {
  color: #1e3466 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: rgb(12.6136363636, 21.8636363636, 42.8863636364) !important;
}

.text-light {
  color: #faf7f2 !important;
}

a.text-light:hover, a.text-light:focus {
  color: rgb(228.75, 213, 186.75) !important;
}

.text-dark {
  color: #262626 !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-body {
  color: #1e3466 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

/* cookieconsent styling */
.cc-window {
  position: fixed;
  overflow: hidden;
  opacity: 1;
  transition: opacity 1s ease;
  font-size: 1rem;
  z-index: 99999;
  background-color: #e9ecef;
  color: #000;
  padding: 1rem;
  /* Position */
  width: 100%;
  max-width: 460px;
  /* Center */
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.cc-window.cc-invisible {
  opacity: 0;
}

.cc-grower {
  max-height: 0;
  overflow: hidden;
  transition: max-height 1s;
}

.cc-revoke {
  display: none;
  visibility: hidden;
}

.cc-btn,
.cc-close,
.cc-link,
.cc-revoke {
  cursor: pointer;
}

.cc-link {
  opacity: 0.8;
  display: inline-block;
  text-decoration: underline;
}
.cc-link:hover {
  opacity: 1;
}
.cc-link:focus {
  outline: 1px dotted;
}
.cc-link:active, .cc-link:visited {
  color: initial;
}

.cc-btn,
.db--overlay-submit {
  display: block;
  padding: 0.4rem 0.8rem;
  border-width: 1px;
  border-radius: 0.1875rem;
  border-style: solid;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  color: #fff;
  border-color: #fa4242;
  background-color: #fa4242;
}
.cc-btn:hover, .cc-btn:focus,
.db--overlay-submit:hover,
.db--overlay-submit:focus {
  color: #fff;
  background-color: rgb(249.0798969072, 31.2201030928, 31.2201030928) !important;
  border-color: rgb(249.0798969072, 31.2201030928, 31.2201030928);
  text-decoration: none;
}
.cc-btn:focus,
.db--overlay-submit:focus {
  outline: 1px dotted;
}

.cc-highlight .cc-btn:first-child {
  background-color: transparent;
  border-color: transparent;
  text-decoration: underline;
}
.cc-highlight .cc-btn:first-child:focus, .cc-highlight .cc-btn:first-child:hover {
  background-color: transparent !important;
  text-decoration: underline;
}
.cc-highlight .cc-btn:last-child {
  font-size: 1.25rem;
}

.cc-close {
  display: block;
  position: absolute;
  top: 0.5em;
  right: 0.5em;
  font-size: 1.6em;
  opacity: 0.9;
  line-height: 0.75;
}

.cc-close:focus,
.cc-close:hover {
  opacity: 1;
}

.cc-message {
  display: block;
  padding: 0 0.5rem;
}

.cc-compliance {
  display: flex;
  flex-wrap: wrap;
}
.cc-compliance .cc-btn {
  flex: 0 0 100%;
  max-width: 100%;
}
.cc-compliance .cc-btn + .cc-btn {
  margin-top: 0.5rem;
}

@media print {
  .cc-revoke,
  .cc-window {
    display: none;
  }
}
@media screen and (max-width: 900px) {
  .cc-btn {
    white-space: normal;
  }
}
@media screen and (max-width: 419px) and (orientation: portrait), screen and (max-width: 767px) and (orientation: landscape) {
  .cc-window {
    top: 0;
    left: 0;
    right: 0;
    bottom: unset;
    transform: translateX(0);
    max-width: none;
  }
}
@media screen and (min-width: 768px) {
  .cc-window {
    top: 50%;
    transform: translate(-50%, -50%);
    padding: 1.75rem;
  }
}
body.dp--cookie-consent::after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(38, 38, 38, 0.4);
  z-index: 1090;
}

.dp--cookie-check {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin: 0.5rem 0;
}
.dp--cookie-check label {
  padding: 5px;
  display: block;
  margin: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  flex: 0 0 50%;
  max-width: 50%;
}

.dp--iframe,
.dp--content-thirdparty {
  display: none;
}
.dp--iframe.dp--loaded,
.dp--content-thirdparty.dp--loaded {
  display: block;
}

.dp--overlay {
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  color: #000;
  border: 1px solid #ced4da;
  border-radius: 0.1875rem;
}

.embed-responsive-16by9 .dp--overlay {
  margin-top: -56.25%;
}

.dp--overlay-header {
  font-weight: bold;
  font-size: 1rem;
}

.dp--overlay-description {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.dp--overlay-button {
  margin-top: 0.5rem;
  font-size: 0.75rem;
}

.db--overlay-submit {
  display: inline-block;
}

.dp--iframe.dp--loaded + .dp--overlay {
  display: none;
}

.frame {
  position: relative;
}
.frame > *:last-child {
  margin-bottom: 0;
}
.frame-header > *:last-child {
  margin-bottom: 1.5rem;
}
@media (min-width: 1104px) {
  .frame-header > *:last-child {
    margin-bottom: 3.125rem;
  }
}
@media (max-width: 575.98px) {
  .frame-header {
    word-break: break-word;
  }
}

.frame-default {
  margin-bottom: 4.5rem;
}
@media (min-width: 992px) {
  .frame-default {
    margin-bottom: 8.5rem;
  }
}
@media (min-width: 1104px) {
  .frame-default {
    margin-bottom: 12.5rem;
  }
}

.frame-type-gridelements .frame-default {
  margin-bottom: 0;
}
.frame-type-gridelements .container.grid-narrow {
  max-width: 300px;
}
@media (min-width: 768px) {
  .frame-type-gridelements .container.grid-narrow {
    max-width: 736px;
  }
}

.frame-space-after-none {
  margin-bottom: 0;
}

.frame-space-after-small {
  margin-bottom: 1.25rem;
}
@media (min-width: 1104px) {
  .frame-space-after-small {
    margin-bottom: 2.625rem;
  }
}

.frame-space-after-medium {
  margin-bottom: 2.5rem;
}
@media (min-width: 992px) {
  .frame-space-after-medium {
    margin-bottom: 5.625rem;
  }
}
@media (min-width: 1104px) {
  .frame-space-after-medium {
    margin-bottom: 8.75rem;
  }
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #0b0b0b;
  opacity: 0.8;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #CCC;
}
.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  right: 0;
  top: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #000;
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media screen and (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
   * Remove all paddings around the image on small screen
   */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
.mfp-image:focus,
.mfp-gallery:focus {
  outline: none;
}

.mfp-with-zoom .mfp-container,
.mfp-with-zoom.mfp-bg {
  opacity: 0;
  -webkit-backface-visibility: hidden;
  /* ideally, transition speed should match zoom duration */
  -webkit-transition: all 0.3s ease-out;
  -moz-transition: all 0.3s ease-out;
  -o-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}

.mfp-with-zoom.mfp-ready .mfp-container {
  opacity: 1;
}

.mfp-with-zoom.mfp-ready.mfp-bg {
  opacity: 0.8;
}

.mfp-with-zoom.mfp-removing .mfp-container,
.mfp-with-zoom.mfp-removing.mfp-bg {
  opacity: 0;
}

.figure-caption {
  color: #fa4242;
  margin-top: 2rem;
}
@media (max-width: 767.98px) {
  .figure-caption {
    font-weight: 700;
  }
}

.textpic .img-fluid {
  min-width: 100%;
}
.textpic figure {
  margin: 0;
}
.textpic .textpic-text .frame-header {
  margin-top: 3rem;
}
.textpic .textpic-text .frame-text {
  margin-top: 1.5rem;
}
.textpic .textpic-text .frame-header + .frame-text {
  margin-top: 0;
}
@media (min-width: 768px) {
  .textpic .figure-caption {
    margin-bottom: 1rem;
  }
  .textpic .textpic-text > *:last-child {
    margin-bottom: 0;
  }
  .textpic .textpic-text .frame-header,
  .textpic .textpic-text .frame-text {
    margin-top: 0;
  }
  .textpic .textpic-image figure {
    width: 90%;
    max-width: 460px;
  }
  .textpic.textpic-right .textpic-image figure {
    margin-left: auto;
    margin-right: 0;
  }
  .textpic.textpic-left .textpic-image figure {
    margin-left: 0;
    margin-right: auto;
  }
}
@media (min-width: 1104px) {
  .textpic .figure-caption {
    margin-top: 2.875rem;
  }
}

@media (max-width: 767.98px) {
  .textpic-room {
    text-align: center;
  }
}
.frame-type-wvier_accordion .frame-header {
  text-align: center;
}

.card {
  position: relative;
}
.card-header {
  border-top: 1px solid #ced4da;
}

.accordion > .card {
  overflow: hidden;
}
.accordion > .card:first-of-type .card-header {
  border-top: 0;
}
.accordion .card-header {
  max-width: 800px;
  margin: 0 auto;
}
.accordion .card-header .btn-link {
  position: relative;
  width: 100%;
  text-align: center;
  padding: 1.25rem;
}
@media (min-width: 992px) {
  .accordion .card-header .btn-link {
    padding: 2rem;
  }
}
.accordion .card-header .btn-link .svg-icon {
  transform: rotate(270deg);
  font-size: 14px;
  transition: transform 0.25s ease-in-out;
}
@media (min-width: 992px) {
  .accordion .card-header .btn-link .svg-icon {
    font-size: 24px;
  }
}
.accordion .card-header .btn-link.collapsed .svg-icon {
  transform: rotate(90deg);
}
.accordion .card-header .card-header-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.accordion .card-body {
  padding-bottom: 1.5rem;
}
@media (min-width: 992px) {
  .accordion .card-body {
    padding-bottom: 2.375rem;
  }
}
.accordion .accordion-text {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
}
.accordion .accordion-image {
  max-width: 920px;
  margin: 0 auto;
}
.accordion .accordion-image .img-fluid {
  margin-top: 0.5rem;
}
@media (min-width: 992px) {
  .accordion .accordion-image .img-fluid {
    margin-top: 1rem;
  }
}

.cover-img {
  width: 100%;
  min-height: 60vh;
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
  background-image: var(--background-image);
}

@media screen and (min-width: 768px) {
  a[href*="tel:"] {
    /*pointer-events: none; --> also not selectable :/ */
    cursor: default;
    color: inherit;
  }
}
@media (min-width: 1104px) {
  p {
    margin-bottom: 1.5rem;
  }
}
img {
  max-width: 100%;
}

a:focus,
button:focus {
  outline: 1px dotted;
  outline-offset: 2px;
}

.skiplinks .sr-only:focus {
  display: inline-block;
  padding: 0.5rem;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
  background-color: #fff;
  z-index: 1070;
}

@media (max-width: 1103.98px) {
  .container {
    padding-left: 36px;
    padding-right: 36px;
  }
}
.container .container {
  padding-left: 0;
  padding-right: 0;
}
.container-narrow {
  max-width: 904px;
  margin: 0 auto;
}

.nav {
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.bg-secondary {
  color: #fff;
}
.bg-secondary h3, .bg-secondary .h3, .bg-secondary h4, .bg-secondary .h4 {
  color: #fa4242;
}
.bg-secondary .btn-outline-secondary {
  color: #fa4242;
  border-color: #fa4242;
}
.bg-secondary .btn-outline-secondary:hover {
  color: #fff;
  background-color: #fa4242;
}

.main-header {
  transition: all 0.25s ease-in-out;
}
.main-header .main-header-inner {
  transition: all 0.25s ease-in-out;
}
.main-header.shrink {
  height: 64px;
  box-shadow: 0 6px 6px 0 rgba(0, 0, 0, 0.15);
}
.main-header.shrink .main-header-inner {
  padding-top: 12px;
  padding-bottom: 12px;
}
@media (min-width: 1104px) {
  .main-header.shrink {
    height: 96px;
  }
  .main-header.shrink .main-header-inner {
    padding-top: 16px;
    padding-bottom: 16px;
  }
}

.menu-open {
  overflow: hidden;
}

.navbar-close {
  position: absolute;
  display: block;
  top: 45px;
  left: 45px;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #fff;
  z-index: 1;
}
@media (min-width: 1104px) {
  .navbar-close {
    top: 110px;
    left: 138px;
  }
}
.navbar-close .navbar-toggler-icon {
  position: relative;
  display: inline-block;
  width: 24px;
  height: 24px;
}
@media (min-width: 1104px) {
  .navbar-close .navbar-toggler-icon {
    width: 40px;
    height: 40px;
  }
}
.navbar-close .navbar-toggler-icon span {
  display: block;
  position: absolute;
  height: 2px;
  width: 100%;
  left: 0;
  background-color: #fff;
}
@media (min-width: 1104px) {
  .navbar-close .navbar-toggler-icon span {
    height: 3px;
  }
}
.navbar-close .navbar-toggler-icon span:nth-child(1) {
  top: 11px;
  transform: rotate(45deg);
}
@media (min-width: 1104px) {
  .navbar-close .navbar-toggler-icon span:nth-child(1) {
    top: 18px;
  }
}
.navbar-close .navbar-toggler-icon span:nth-child(2) {
  top: 11px;
  transform: rotate(-45deg);
}
@media (min-width: 1104px) {
  .navbar-close .navbar-toggler-icon span:nth-child(2) {
    top: 18px;
  }
}
.navbar-close:hover {
  padding: 0;
}

.navbar-back {
  position: absolute;
  display: none;
  top: 58px;
  left: 35px;
  width: 22px;
  height: 45px;
  padding: 0;
  border: 0;
  background-color: transparent;
  color: #fff;
  transform: rotate(-90deg);
  transform-origin: top center;
  z-index: 2;
}
.navbar-back > svg {
  position: absolute;
  top: 0;
  left: -12px;
}
@media (max-width: 1103.98px) {
  .navbar-back > svg {
    width: 45px;
    height: 45px;
  }
}
@media (min-width: 1104px) {
  .navbar-back > svg {
    left: -20px;
  }
}
.navbar-back:hover {
  padding: 0;
}
@media (min-width: 1104px) {
  .navbar-back {
    top: 130px;
    left: 115px;
    width: 36px;
    height: 76px;
  }
}

.menu-overlay .menu-inner {
  position: relative;
  height: 100%;
  padding-top: 70px;
  overflow-y: auto;
}
@media (min-width: 992px) {
  .menu-overlay .menu-inner {
    padding-top: 92px;
  }
}
.menu-overlay.submenu-visible .navbar-close {
  display: none;
}
.menu-overlay.submenu-visible .navbar-back {
  display: block;
}

.navbar-nav {
  list-style: none;
  padding: 0;
  margin: 0;
}

.dl-menuwrapper {
  position: relative;
  width: 100%;
  margin: 2.875rem 0 0;
}
@media (min-width: 1104px) {
  .dl-menuwrapper {
    margin: 2.25rem 0 0;
  }
}
.dl-menuwrapper ul {
  padding: 0;
  list-style: none;
  transform-style: preserve-3d;
  text-align: center;
}
.dl-menuwrapper li {
  position: relative;
  /* Hide the inner submenus */
}
.dl-menuwrapper li a {
  display: inline-block;
  position: relative;
  padding: 0.5rem 0;
  line-height: 1.26666667;
  font-size: 1.875rem;
  font-family: "Mixta Sharp", serif;
  font-weight: 700;
  color: #fff;
  text-decoration: none;
}
@media (min-width: 1104px) {
  .dl-menuwrapper li a {
    padding: 0.375rem 0;
    font-size: 2.3125rem;
  }
}
.dl-menuwrapper li .dl-submenu {
  display: none;
}
.dl-menuwrapper .dl-menu {
  margin: 0;
  position: absolute;
  width: 100%;
  pointer-events: none;
}
.dl-menuwrapper .dl-menu.dl-menuopen {
  pointer-events: auto;
}
.dl-menuwrapper .dl-menu.dl-menu-toggle {
  transition: all 0.3s ease;
}

/*
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link.
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
  display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
  display: block;
}

/* Dynamically added submenu outside of the menu context */
.dl-menuwrapper > .dl-submenu {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  margin: 0;
}

/* Animation classes for moving out and in */
.dl-menu.dl-animate-out {
  animation: MenuAnimOut 0.4s ease;
}

@keyframes MenuAnimOut {
  100% {
    transform: translateZ(300px);
    opacity: 0;
  }
}
.dl-menu.dl-animate-in {
  animation: MenuAnimIn 0.4s ease;
}

@keyframes MenuAnimIn {
  0% {
    transform: translateZ(300px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0px);
    opacity: 1;
  }
}
.dl-menuwrapper > .dl-submenu.dl-animate-in {
  animation: SubMenuAnimIn 0.4s ease;
}

@keyframes SubMenuAnimIn {
  0% {
    transform: translateZ(-300px);
    opacity: 0;
  }
  100% {
    transform: translateZ(0px);
    opacity: 1;
  }
}
.dl-menuwrapper > .dl-submenu.dl-animate-out {
  animation: SubMenuAnimOut 0.4s ease;
}

@keyframes SubMenuAnimOut {
  0% {
    transform: translateZ(0px);
    opacity: 1;
  }
  100% {
    transform: translateZ(-300px);
    opacity: 0;
  }
}
/* No JS Fallback */
.no-js .dl-menuwrapper .dl-menu {
  position: relative;
  opacity: 1;
  transform: none;
}

.no-js .dl-menuwrapper li .dl-submenu {
  display: block;
}

.no-js .dl-menuwrapper li.dl-back {
  display: none;
}

.footer {
  padding: 3.375rem 0 2.625rem;
  font-size: 1.125rem;
  line-height: 1.5;
}
.footer > .container {
  position: relative;
}
@media (min-width: 992px) {
  .footer {
    padding: 4.5rem 0 2.25rem;
  }
}
@media (min-width: 1104px) {
  .footer {
    font-size: 1.375rem;
  }
}
@media (max-width: 991.98px) {
  .footer-navigation, .footer-main-navigation {
    line-height: 1.944444;
  }
}
.footer-logo {
  margin-bottom: 3.375rem;
}
@media (min-width: 992px) {
  .footer-logo {
    margin-bottom: 3.75rem;
  }
}
.footer-sociallinks .list-inline-item:not(:last-child) {
  margin-right: 0.875rem;
}
.footer-meta-navigation {
  font-size: 0.9375rem;
}
.footer-meta-navigation .list-inline {
  margin-bottom: 0;
}
.footer-meta-navigation .list-inline-item + .list-inline-item::before {
  display: inline-block;
  padding-right: 0.5rem;
  content: "/";
}
@media (max-width: 575.98px) {
  .footer .order-xs-last {
    order: 13;
  }
}

.scroll-top {
  position: absolute;
  right: 36px;
  bottom: 94px;
  width: 32px;
  height: 64px;
  overflow: hidden;
  transition: opacity 0.25s ease-in-out;
}
@media (min-width: 992px) {
  .scroll-top {
    top: 0;
    bottom: auto;
  }
}
@media (min-width: 1104px) {
  .scroll-top {
    right: 8px;
    width: 36px;
    height: 76px;
  }
}
.scroll-top > svg {
  fill: #fa4242;
  position: absolute;
  left: -16px;
}
@media (max-width: 1103.98px) {
  .scroll-top > svg {
    width: 64px;
    height: 64px;
  }
}
@media (min-width: 1104px) {
  .scroll-top > svg {
    left: -20px;
  }
}

.holidaycheck-container .hc-widget,
.tripadvisor-container #CDSWIDSSP {
  max-width: 100%;
  margin: 0 auto;
}