// Textpic

.figure-caption{
    color: $primary;
    margin-top: 2rem;

    @include media-breakpoint-down(sm) {
        font-weight: $font-weight-bold;
    }
}

.textpic {

    .img-fluid {
        min-width: 100%;
    }

    figure {
        margin: 0;
    }

    .textpic-text {
        .frame-header {
            margin-top: 3rem;
        }
        .frame-text {
            margin-top: 1.5rem;
        }

        .frame-header + .frame-text {
            margin-top: 0;
        }
    }

    @include media-breakpoint-up(md) {
        .figure-caption {
            margin-bottom: 1rem;
        }

        .textpic-text {
            > *:last-child {
                margin-bottom: 0;
            }

            .frame-header,
            .frame-text {
                margin-top: 0;
            }
        }

        .textpic-image {
            figure {
                width: 90%;
                max-width: 460px;
            }
        }

        &.textpic-right {
            .textpic-image {
                figure {
                    margin-left: auto;
                    margin-right: 0;
                }
            }
        }

        &.textpic-left {
            .textpic-image {
                figure {
                    margin-left: 0;
                    margin-right: auto;
                }
            }
        }
    }

    @include media-breakpoint-up(xl) {
        .figure-caption {
            margin-top: 2.875rem;
        }
    }
}

@include media-breakpoint-down(sm) {
    .textpic-room {
        text-align: center;
    }
}


