// DL MENU

.dl-menuwrapper {
    position: relative;
    width: 100%;
    margin: 2.875rem 0 0;

    @include media-breakpoint-up(xl) {
        margin: 2.25rem 0 0;
    }

    ul {
        padding: 0;
        list-style: none;
        transform-style: preserve-3d;
        text-align: center;
    }

    li {
        position: relative;

        a {
            display: inline-block;
            position: relative;
            padding: 0.5rem 0;
            line-height: 1.26666667;
            font-size: 1.875rem;
            font-family: $font-family-serif;
            font-weight: $font-weight-bold;
            color: $white;
            text-decoration: none;

            @include media-breakpoint-up(xl) {
                padding: 0.375rem 0;
                font-size: 2.3125rem;
            }
        }

        /* Hide the inner submenus */
        .dl-submenu {
            display: none;
        }
    }

    .dl-menu {
        margin: 0;
        position: absolute;
        width: 100%;
        pointer-events: none;

        &.dl-menuopen {
            pointer-events: auto;
        }

        &.dl-menu-toggle {
            transition: all 0.3s ease;
        }
    }
}

/*
When a submenu is openend, we will hide all li siblings.
For that we give a class to the parent menu called "dl-subview".
We also hide the submenu link.
The opened submenu will get the class "dl-subviewopen".
All this is done for any sub-level being entered.
*/
.dl-menu.dl-subview li,
.dl-menu.dl-subview li.dl-subviewopen > a,
.dl-menu.dl-subview li.dl-subview > a {
    display: none;
}

.dl-menu.dl-subview li.dl-subview,
.dl-menu.dl-subview li.dl-subview .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu,
.dl-menu.dl-subview li.dl-subviewopen > .dl-submenu > li {
    display: block;
}

/* Dynamically added submenu outside of the menu context */
.dl-menuwrapper > .dl-submenu {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    margin: 0;
}

/* Animation classes for moving out and in */

.dl-menu.dl-animate-out {
    animation: MenuAnimOut 0.4s ease;
}

@keyframes MenuAnimOut {
    0% { }
    100% {
        transform: translateZ(300px);
        opacity: 0;
    }
}

.dl-menu.dl-animate-in {
    animation: MenuAnimIn 0.4s ease;
}

@keyframes MenuAnimIn {
    0% {
        transform: translateZ(300px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0px);
        opacity: 1;
    }
}

.dl-menuwrapper > .dl-submenu.dl-animate-in {
    animation: SubMenuAnimIn 0.4s ease;
}


@keyframes SubMenuAnimIn {
    0% {
        transform: translateZ(-300px);
        opacity: 0;
    }
    100% {
        transform: translateZ(0px);
        opacity: 1;
    }
}


.dl-menuwrapper > .dl-submenu.dl-animate-out {
    animation: SubMenuAnimOut 0.4s ease;
}



@keyframes SubMenuAnimOut {
    0% {
        transform: translateZ(0px);
        opacity: 1;
    }
    100% {
        transform: translateZ(-300px);
        opacity: 0;
    }
}

/* No JS Fallback */
.no-js .dl-menuwrapper .dl-menu {
    position: relative;
    opacity: 1;
    transform: none;
}

.no-js .dl-menuwrapper li .dl-submenu {
    display: block;
}

.no-js .dl-menuwrapper li.dl-back {
    display: none;
}
