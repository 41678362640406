@import "variables";

.frame-type-wvier_accordion {
	.frame-header {
		text-align : center;
	}
}

.card {
	position : relative;

	&-header {
		border-top : 1px solid $gray-400;
	}
}

.accordion {
	> .card {
		overflow : hidden;

		&:first-of-type {
			.card-header {
				border-top : 0;
			}
		}
	}

	.card-header {
		max-width : 800px;
		margin    : 0 auto;

		.btn-link {
			position   : relative;
			width      : 100%;
			text-align : center;
			padding    : 1.25rem;

			@include media-breakpoint-up(lg) {
				padding : 2rem;
			}

			.svg-icon {
				transform  : rotate(270deg);
				font-size  : 14px;
				transition : transform 0.25s ease-in-out;

				@include media-breakpoint-up(lg) {
					font-size : 24px;
				}
			}

			&.collapsed {
				.svg-icon {
					transform : rotate(90deg);
				}
			}
		}

		.card-header-icon {
			position  : absolute;
			right     : 0;
			top       : 50%;
			transform : translateY(-50%);
		}
	}

	.card-body {
		padding-bottom : 1.5rem;

		@include media-breakpoint-up(lg) {
			padding-bottom : 2.375rem;
		}
	}

	.accordion-text {
		text-align : center;
		max-width  : 800px;
		margin     : 0 auto;
	}

	.accordion-image {
		max-width : 920px;
		margin    : 0 auto;

		.img-fluid {
			margin-top : 0.5rem;

			@include media-breakpoint-up(lg) {
				margin-top : 1rem;
			}
		}
	}
}