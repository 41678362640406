@import "variables";
//Bootstrap Required
@import "../../Contrib/Bootstrap/scss/functions";
@import "../../Contrib/Bootstrap/scss/variables";
@import "../../Contrib/Bootstrap/scss/mixins";

$colors       : map-remove($colors, "indigo", "purple", "pink", "orange", "yellow", "green", "teal", "cyan", "white", "gray", "gray-dark");
$theme-colors : map-remove($theme-colors, "success", "info", "warning", "danger");

//Bootstrap Optional
//@import "../../Contrib/Bootstrap/scss/type"; -> custom
//@import "../../Contrib/Bootstrap/scss/images";
//@import "../../Contrib/Bootstrap/scss/code";
//@import "../../Contrib/Bootstrap/scss/grid"; -> custom
//@import "../../Contrib/Bootstrap/scss/tables";
//@import "../../Contrib/Bootstrap/scss/forms";
@import "../../Contrib/Bootstrap/scss/transitions";
@import "../../Contrib/Bootstrap/scss/dropdown";
//@import "../../Contrib/Bootstrap/scss/button-group";
//@import "../../Contrib/Bootstrap/scss/input-group";
//@import "../../Contrib/Bootstrap/scss/custom-forms";
//@import "../../Contrib/Bootstrap/scss/nav";
//@import "../../Contrib/Bootstrap/scss/navbar";
//@import "../../Contrib/Bootstrap/scss/card" -> custom;
//@import "../../Contrib/Bootstrap/scss/breadcrumb";
//@import "../../Contrib/Bootstrap/scss/pagination";
//@import "../../Contrib/Bootstrap/scss/badge";
//@import "../../Contrib/Bootstrap/scss/jumbotron";
//@import "../../Contrib/Bootstrap/scss/alert";
//@import "../../Contrib/Bootstrap/scss/progress";
//@import "../../Contrib/Bootstrap/scss/media";
//@import "../../Contrib/Bootstrap/scss/list-group";
//@import "../../Contrib/Bootstrap/scss/close";
//@import "../../Contrib/Bootstrap/scss/toasts";
//@import "../../Contrib/Bootstrap/scss/modal";
//@import "../../Contrib/Bootstrap/scss/tooltip";
//@import "../../Contrib/Bootstrap/scss/popover";
//@import "../../Contrib/Bootstrap/scss/carousel";
//@import "../../Contrib/Bootstrap/scss/spinners";
//@import "../../Contrib/Bootstrap/scss/utilities"; -> custom
@import "../../Contrib/Bootstrap/scss/print";

// Custom Styles
@import "bsgrid";
@import "bsutilities";

@import "dpcookieconsent";
@import "frame";
@import "magnific";
@import "textpic";
@import "accordion";
@import "cover";


@media screen and (min-width : map_get($grid-breakpoints, md)) {
	a[href*="tel:"] {
		/*pointer-events: none; --> also not selectable :/ */
		cursor : default;
		color  : inherit;
	}
}

@media (min-width : map_get($grid-breakpoints, xl)) {
	p {
		margin-bottom : 1.5rem;
	}
}

img {
	max-width : 100%;
}

a:focus,
button:focus {
	outline        : 1px dotted;
	outline-offset : 2px;
}

// Skiplinks
.skiplinks {
	.sr-only {
		&:focus {
			display          : inline-block;
			padding          : 0.5rem;
			width            : auto;
			height           : auto;
			overflow         : visible;
			clip             : auto;
			white-space      : normal;
			background-color : $white;
			z-index          : $zindex-tooltip;
		}
	}
}

// Layout
.container {
	@include media-breakpoint-down(lg) {
		padding-left  : 36px;
		padding-right : 36px;
	}

	.container {
		padding-left  : 0;
		padding-right : 0;
	}

	&-narrow {
		max-width : 904px;
		margin    : 0 auto;
	}
}

.nav {
	padding-left  : 0;
	margin-bottom : 0;
	list-style    : none;
}

.bg-secondary {
	color : $white;

	h3, .h3, h4, .h4 {
		color : $primary;
	}

	.btn-outline-secondary {
		color        : $primary;
		border-color : $primary;

		&:hover {
			color            : $white;
			background-color : $primary;
		}
	}
}

// Navigation
.main-header {
	transition : all 0.25s ease-in-out;

	.main-header-inner {
		transition : all 0.25s ease-in-out;
	}

	&.shrink {
		height     : 64px;
		box-shadow : 0 6px 6px 0 rgba($black, 0.15);

		.main-header-inner {
			padding-top    : 12px;
			padding-bottom : 12px;
		}

		@include media-breakpoint-up(xl) {
			height : 96px;
			.main-header-inner {
				padding-top    : 16px;
				padding-bottom : 16px;
			}
		}
	}
}

.menu-open {
	overflow : hidden;
}

.navbar-close {
	position         : absolute;
	display          : block;
	top              : 45px;
	left             : 45px;
	padding          : 0;
	border           : 0;
	background-color : transparent;
	color            : $white;
	z-index          : 1;

	@include media-breakpoint-up(xl) {
		top  : 110px;
		left : 138px;
	}

	.navbar-toggler-icon {
		position : relative;
		display  : inline-block;
		width    : 24px;
		height   : 24px;

		@include media-breakpoint-up(xl) {
			width  : 40px;
			height : 40px;
		}

		span {
			display          : block;
			position         : absolute;
			height           : 2px;
			width            : 100%;
			left             : 0;
			background-color : $white;

			@include media-breakpoint-up(xl) {
				height : 3px;
			}
		}

		span:nth-child(1) {
			top       : 11px;
			transform : rotate(45deg);

			@include media-breakpoint-up(xl) {
				top : 18px;
			}
		}

		span:nth-child(2) {
			top       : 11px;
			transform : rotate(-45deg);

			@include media-breakpoint-up(xl) {
				top : 18px;
			}
		}
	}

	&:hover {
		padding : 0;
	}
}

.navbar-back {
	position         : absolute;
	display          : none;
	top              : 58px;
	left             : 35px;
	width            : 22px;
	height           : 45px;
	padding          : 0;
	border           : 0;
	background-color : transparent;
	color            : $white;
	transform        : rotate(-90deg);
	transform-origin : top center;
	z-index          : 2;

	> svg {
		position : absolute;
		top      : 0;
		left     : -12px;

		@include media-breakpoint-down(lg) {
			width  : 45px;
			height : 45px;
		}

		@include media-breakpoint-up(xl) {
			left : -20px;
		}
	}

	&:hover {
		padding : 0;
	}

	@include media-breakpoint-up(xl) {
		top    : 130px;
		left   : 115px;
		width  : 36px;
		height : 76px;
	}
}

.menu-overlay {
	.menu-inner {
		position    : relative;
		height      : 100%;
		padding-top : 70px;
		overflow-y  : auto;

		@include media-breakpoint-up(lg) {
			padding-top : 92px;
		}
	}

	&.submenu-visible {
		.navbar-close {
			display : none;
		}

		.navbar-back {
			display : block;
		}
	}
}

.navbar-nav {
	list-style : none;
	padding    : 0;
	margin     : 0;
}

@import "dlmenu";

// Footer
.footer {
	padding     : 3.375rem 0 2.625rem;
	font-size   : 1.125rem;
	line-height : 1.5;

	> .container {
		position : relative;
	}

	@include media-breakpoint-up(lg) {
		padding : 4.5rem 0 2.25rem;
	}

	@include media-breakpoint-up(xl) {
		font-size : 1.375rem;
	}

	@include media-breakpoint-down(md) {
		&-navigation,
		&-main-navigation {
			line-height : 1.944444;
		}
	}

	&-logo {
		margin-bottom : 3.375rem;

		@include media-breakpoint-up(lg) {
			margin-bottom : 3.75rem;
		}
	}

	&-sociallinks {
		.list-inline-item:not(:last-child) {
			margin-right : 0.875rem;
		}
	}

	&-meta-navigation {
		font-size : 0.9375rem;

		.list-inline {
			margin-bottom : 0;
		}

		.list-inline-item + .list-inline-item::before {
			display       : inline-block;
			padding-right : .5rem;
			content       : "/";
		}
	}

	@include media-breakpoint-down(xs) {
		.order-xs-last {
			order : 13;
		}
	}
}

//
// Scrolltop
//
.scroll-top {
	position   : absolute;
	right      : 36px;
	bottom     : 94px;
	width      : 32px;
	height     : 64px;
	overflow   : hidden;
	transition : opacity 0.25s ease-in-out;

	@include media-breakpoint-up(lg) {
		top    : 0;
		bottom : auto;
	}

	@include media-breakpoint-up(xl) {
		right  : 8px;
		width  : 36px;
		height : 76px;
	}

	> svg {
		fill     : $primary;
		position : absolute;
		left     : -16px;

		@include media-breakpoint-down(lg) {
			width  : 64px;
			height : 64px;
		}

		@include media-breakpoint-up(xl) {
			left : -20px;
		}
	}
}

.holidaycheck-container .hc-widget,
.tripadvisor-container #CDSWIDSSP {
	max-width : 100%;
	margin    : 0 auto;
}
